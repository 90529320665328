import { Fragment, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from 'layouts/default';

import windowCheck  from 'utils/helpers/window-check';
import useOnScreen from 'utils/hooks/use-onscreen';

import HighlightCards from 'components/content-blocks/highlight';
import MediaObject from 'components/content-blocks/media-object';
import Yoast from 'components/globals/yoast';
import GreenHouse from 'components/greenhouse/careers';
import { HeroDefaultSignup } from 'components/hero/default-signup';
import Link from 'components/link/link';
import StickyCta from 'components/sticky-cta/sticky-cta';

import './styles/careers.scss';

export default function Careers() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "careers" } }) {
        edges {
          node {
            wordpress_id
            yoast_head_json {
              title
              description
              canonical
              og_title
              og_description
              og_image {
                url {
                  source_url
                }
              }
              og_url
              og_type
              twitter_title
              twitter_description
              twitter_image {
                source_url
              }
            }
            acf {
              hero {
                background_desktop_image {
                  source_url
                }
                background_mobile_image {
                  source_url
                }
                title
                copy
                jump_link {
                  button_text
                  link_id
                }
              }
              cards_4 {
                card {
                  image {
                    source_url
                  }
                  title
                  copy
                }
              }
              cards_5 {
                card {
                  image {
                    source_url
                  }
                  title
                  copy
                }
              }
              text_blocks {
                title
                wysiwyg
              }
              text_blocks_2 {
                title
                wysiwyg
              }
              unordered_list {
                text_items {
                  image {
                    source_url
                  }
                }
              }
              media_object {
                sub_title
                title
                desktop_image {
                  alt_text
                  source_url
                }
                mobile_image {
                  alt_text
                  source_url
                }
                wysiwyg
              }
              image_text_block_2 {
                title
                copy
                image {
                    source_url
                }
                mobile_image {
                    source_url
                }
              }
              image_text_block_3 {
                title
                copy
                wysiwyg
              }
              basic_title_subtitle_copy_image {
                copy
                title
                wysiwyg
                desktop_image {
                  source_url
                }
                mobile_image {
                  source_url
                }
              }
              image_group {
                copy
                title
              }
            }
          }
        }
      }
    }
  `);

  const [isHeroVisible, setIsHeroVisible] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const jobsElement = useRef(null);
  const isJobsElementVisible = useOnScreen(jobsElement);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const hero = pageContext.hero;
  const coreValues = pageContext.unordered_list.text_items;
  const imageGroup = pageContext.image_group;
  const wiwPeople = pageContext.image_text_block_2;
  const wiwCulture = pageContext.image_text_block_3;
  const wiwPeopleText = pageContext.text_blocks_2;
  const summit = pageContext.basic_title_subtitle_copy_image;
  const cardsData4 = pageContext.cards_4;
  const cardsData5 = pageContext.cards_5;
  const mediaObject = pageContext.media_object[0];
  const secondMediaObject = pageContext.media_object[1];
  const thirdMediaObject = pageContext.media_object[2];

  const breakpoints = useBreakpoint();

  const [showBackButton, setShowBackButton] = useState(false);
  useEffect(() => {
    // if this page has a value in the query string for a Greenhouse job, add #jobs to url to get it to scroll
    if (windowCheck && window.location.search.indexOf('?gh_jid') === 0) {
      setShowBackButton(true);
      window.location.hash = 'jobs';
    }
  }, []);

  return (
    <Layout
      hideSignup
      setIsFooterVisible={ setIsFooterVisible }
    >
      <Yoast { ...data.allWordpressPage.edges[0].node.yoast_head_json } />
      <div className="careers-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup
          { ...hero }
          className="has-text-centered"
          setIsHeroVisible={ setIsHeroVisible }
          jumpLinkClass="is-primary is-outlined"
        />

        <section className="section wiw-culture has-text-centered">
          <div className="container">
            <h2 className="title headline">{wiwCulture.title}</h2>
            <p className="copy">{wiwCulture.copy }</p>
            <div className="wiw-culture-images" dangerouslySetInnerHTML={ { __html: wiwCulture.wysiwyg } } />
          </div>
        </section>

        <HighlightCards
          { ...cardsData4 }
          hasImage
          hasCopy
          hasTitle
          className="career-perks"
          startingIndex="1"
        />

        <MediaObject { ...mediaObject } className="reverse" />

        <section className="section wiw-people has-text-centered">
          <div className="container">
            {!breakpoints.sm && <img className="wiw-people-bg" src="https://marketing-assets.wheniwork-production.com/2024/01/19111737/wiw-people-bg.svg" role="presentation" />}
            <header>
              <h2 className="title headline">{wiwPeople.title}</h2>
              <p className="copy">{wiwPeople.copy }</p>
            </header>
            <picture className="image-container">
              <source
                srcSet={ wiwPeople.image.source_url }
                media="(min-width: 769px)"
              />
              <img className="wiw-people-photo" src={ wiwPeople.mobile_image.source_url } alt="When I Work Employee Group Picture" />
            </picture>
          </div>
        </section>

        <MediaObject { ...thirdMediaObject } className="reverse" />

        <section className="section core-values has-text-centered">
          <div className="container-xlarge">
            <img className="core-values-bg" loading="lazy" src="https://marketing-assets.wheniwork-production.com/2024/01/22093036/careers-values-background-green-half-circle.svg" role="presentation" />
            <h2 className="headline">{wiwPeopleText.title}</h2>
            <div
              className="copy"
              dangerouslySetInnerHTML={ { __html: wiwPeopleText.wysiwyg } }
            />
            <ul className="columns">
              {coreValues.map((index, value) => (
                <li key={ value } className="column is-half-mobile">
                  <img src={ index.image.source_url } loading="lazy" />
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="section summit">
          <div className="container-large">
            <header>
              {breakpoints.sm && <div className="slogan">
                <img className="slogan-badge" src={ summit.desktop_image.source_url } role="presentation" />
              </div>}
              <h2 className="headline">{summit.title}</h2>
              <p className="copy">{summit.copy}</p>
            </header>
            <div className="summit-pics" dangerouslySetInnerHTML={ { __html: summit.wysiwyg } } />
            {!breakpoints.sm && <div className="slogan">
              <img className="slogan-badge" src={ summit.desktop_image.source_url } role="presentation" />
            </div>}
            
          </div>
        </section>

        <MediaObject { ...secondMediaObject } className="reverse less-padding" />

        <HighlightCards
          { ...cardsData5 }
          hasImage
          hasCopy
          hasTitle
          className="more-career-perks"
          startingIndex="1"
        />

        <section className="image-group-cool-cred">
          <div className="container">
            <h2 className="title">{imageGroup[0].title}</h2>
            <p className="copy">{imageGroup[0].copy}</p>
            <div className="awards">
              <img className="award" loading="lazy" src="https://marketing-assets.wheniwork-production.com/2020/09/16221151/Workplace-Award-top-2020.png" />
              <img className="award" loading="lazy" src="https://marketing-assets.wheniwork-production.com/2020/09/16221113/Workplace-Award-top-2019-1.png" />
              <img className="award" loading="lazy" src="https://marketing-assets.wheniwork-production.com/2020/09/16221304/Workplace-Award-coolest-2018.png" />
              <img className="award" loading="lazy" src="https://marketing-assets.wheniwork-production.com/2022/12/09144051/top-150-workplace-award.svg" />
            </div>
          </div>
        </section>

        <section className="section greenhouse-section" id="jobs">
          <div ref={ jobsElement } className="container">
            <h2 className="headline has-text-centered">Open Positions</h2>
            {showBackButton &&
              <a href="/careers#jobs" className="button button-back is-rounded is-filled-green">Back to all positions</a>
            }
            <GreenHouse />
          </div>
        </section>

        <StickyCta
          className={ `${!isHeroVisible && !isFooterVisible && !isJobsElementVisible ? 'is-viewable' : ''}` }
          data-section="fixed-jump-link"
        >
          {breakpoints.sm ? (
            <div className="text-container">
              <Link to={ `#${pageContext.hero.jump_link.link_id}` }>
                <span>
                  We're Hiring! {pageContext.hero.jump_link.button_text}
                </span>
              </Link>
            </div>
          ) : (
            <Fragment>
              <span>We're Hiring</span>
              <Link
                to={ `#${pageContext.hero.jump_link.link_id}` }
                className="button is-filled-yellow"
              >
                {pageContext.hero.jump_link.button_text}
              </Link>
            </Fragment>
          )}
        </StickyCta>
      </div>
    </Layout>
  );
}
